.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.input-field .react-input-emoji--input {
  height: 100px;
}

.detail-btn {
  margin-right: 10px;
}
