.simplebar-scrollbar:before {
  opacity: 1;
  background: #d3d3d3;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 50%;
}

.sticky-table-header {
  position: sticky;
  top: -2px;
  z-index: 1;
  background-color: #f3f4f9;
  height: 50px;
}

.simplebar-track.simplebar-vertical {
  width: 0;
}
