.detail-profile-image {
  height: 70px;
  width: 70px;
  border-radius: 35px;
  border: 1px solid gray;
}

.detail-image-grid {
  width: 100px;
  height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #eceff5;
}

.profileImg {
  width: 130px;
  height: 130px;
  border: 1px solid;
  border-radius: 65px;
}

.right-logo {
  height: 25px;
}

/*---Image Preview class---*/
.kyc-image {
  height: 65px;
  width: 95px;
  object-fit: cover;
  cursor: pointer;
}

/* App.css */

.app {
  text-align: center;
  margin-top: 20px;
}

h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.image-container img {
  max-width: 100px;
  cursor: pointer;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: transparent;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal img {
  max-width: 100%;
  height: auto;
}

.close-button {
  position: fixed;
  top: 7rem;
  right: 22rem;
  font-size: 50px;
  color: #171515b0;
  padding: 0px;
  cursor: pointer;
  color: #fff;
}

.close-button:hover {
  color: red;
}
/*-------------------------------------------Notification-Details--------------------------------------------------------------*/
.failed-notify {
  color: #fff;
  background: red;
  padding: 3px;
  border-radius: 3px;
  margin-bottom: 5px;
}
.success-notify {
  color: #fff;
  background: green;
  padding: 3px;
  border-radius: 3px;
  margin-bottom: 5px;
}
.bottom-notify {
  display: flex !important;
  justify-content: center !important;
}
.notification-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


