.field-icon {
  float: right;
  margin-right: 0;
  margin-top: -45px;
  position: relative;
  z-index: 2;
}

.container {
  padding-top: 50px;
  margin: auto;
}
