.button {
  background-color: #126782;
  color: #fff;
  cursor: "pointer";
}

.button:hover {
  background-color: #023047;
  color: #fff;
  cursor: "pointer";
}

.cmn-input-brdr {
  border-radius: 8px;
  border: 2px solid;
  border-color: #e5eaf2;
}
