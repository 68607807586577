.no-data {
  margin-top: 1em;
  max-width: 30%;
  max-height: calc(100vh - 175px);
  opacity: 0.1;
}
.bottom-border {
  border-bottom: 1px solid #dadada4f;
  padding-bottom: 15px !important;
}
.bottom-border:last-child {
  border-bottom: none;
}
.text-justify {
  text-align: justify;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.swal2-cancel {
  color: #023047 !important;
  box-shadow: none !important;
  border: 1px solid #023047 !important;
}
.swal2-title {
  color: black !important;
  padding-top: 0.2em !important;
}

.custom-popup {
  text-align: center !important;
}

.custom-popup .title {
  font-size: 20px !important;
  font-weight: normal !important;
  margin: 0 !important;
}

.custom-popup .sub-title {
  font-size: 30px !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
  color: black !important;
}

.hidden {
  display: none;
}

@media only screen and (max-width: 900px) {
  .filter-menu {
    margin-top: 10px;
  }
}

.letter-spacing {
  letter-spacing: 1px;
}

#pdf-container {
  margin-top: 50px;
}

.pagination-count {
  position: fixed;
  bottom: 20px;
}
