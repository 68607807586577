.avatar {
  width: 40px;
  height: 40px;
  font-size: larger;
  margin-top: 8px;
}

.post-text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.post-notes {
  -webkit-line-clamp: 2;
  max-height: 60px;
  letter-spacing: 1px;
}

.title-post {
  -webkit-line-clamp: 1;
}

.post-detail:hover {
  color: hsl(206, 100%, 40%);
}

.sort-order.active {
  background-color: rgb(255, 225, 53, 0.6);
}
.sort-order:hover {
  background-color: #ffffff;
}

.card-flag-image {
  border-radius: 100px;
  height: 26px;
  width: 26px;
  vertical-align: middle;
  border: 1px solid black;
  margin-right: 2px;
  margin-left: 5px;
}

.image-grid .css-8oi9w1-MuiGrid-root {
  padding-left: 0px !important;
}
.image-grid {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  margin-left: 0;
}
@media only screen and (max-width: 1060px) {
  .card-flag-image {
    margin-left: 10px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1460px) {
  .card-flag-image {
    margin-left: 0px;
  }
}
.date-picker {
  padding: 12px;
  border-radius: 7px;
  border: darkgrey solid 1px;
}
.date-picker-modal {
  border-radius: 8px;
  border: 2px solid;
  border-color: #e5eaf2;
  box-sizing: content-box;
  padding: 16.5px 76px 16.5px 15px;
}
.date-picker-modal .react-datepicker__close-icon {
  right: -75px !important;
}

.date-picker-modal:hover {
  border-color: #1d2438;
}
.date-picker-modal:focus {
  border-color: #2499ef;
  outline: none;
}
.react-datepicker__close-icon::after {
  color: black !important;
  background-color: #126782 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #126782 !important;
  font-weight: none !important;
}

.toflag {
  margin-left: 4px;
  margin-right: 4px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #126782 !important;
  color: black !important;
}

@media only screen and (max-width: 500px) {
  .menu {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .date-category {
    display: flex;
    justify-content: space-between;
    gap: 4px;
  }
}

@media only screen and (max-width: 991px) {
  .card-flag-image {
    height: 25px;
    width: 25px;
  }
}

.css-zow5z4-MuiGrid-root > .MuiGrid-item {
  padding: 12px 12px 12px 12px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  top: 7px;
  left: 6px;
  padding: 0rem !important;
}

.export-button {
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid gray;
  padding: 7px 10px 7px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.asc-des {
  height: 27px;
  width: 27px;
}

.post-card-Profile-image {
  height: 40px;
  width: 40px;
  margin-top: 8px;
  margin-bottom: 10px;
  border: 1px solid;
  border-radius: 22px;
}

.reset-button:hover {
  color: black;
  border-color: black;
  background-color: #f3f4f9;
}

.scroll-table {
  border-spacing: 0 5px;
}
