.Profile-photo {
  height: 90px;
  width: 90px;
  border-radius: 45px;
  border: 1px solid;
}

.css-10sn0x3-MuiTabs-root .MuiButtonBase-root:last-of-type {
  margin: 0;
}

.css-10sn0x3-MuiTabs-root .MuiButtonBase-root:first-of-type {
  margin: 0;
}

.tab-button {
  display: flex;
  align-items: flex-start;
}

.input--file {
  position: relative;
  color: #7f7f7f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
}

.input--file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  width: -webkit-fill-available;
}

.input-span {
}
